import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import PDF from './PDF/main'
import Login from './Login/index'
const App = () => (
    <Login />
);

ReactDOM.render(<App />, document.getElementById('root'));
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer } from '@react-pdf/renderer';
// import PDF from './PDF/demo'

// const App = () => (
//   <PDFViewer style={{width:"100%"}} height={1000}>
//     <PDF />
//   </PDFViewer>
// );

//  ReactDOM.render(<App />, document.getElementById('root'));