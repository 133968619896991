import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import logo from './logo.png'
import No1Brand from './No1Brand.png'
import fiba from './fiba.png'
import huidu from './huidu.png'
import genergy from './genergy.png'
import auth from './auth.png'


Font.register('https://fonts.gstatic.com/s/kadwa/v2/rnCm-x5V0g7ipiTAT8M.ttf', { family: 'KadwaRegular' });
Font.register('https://fonts.gstatic.com/s/kadwa/v2/rnCr-x5V0g7ipix7atM5kn0.ttf', { family: 'KadwaBold' });
Font.register('https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf', { family: 'MontserratRegular' });

const  numberWithCommas=(x)=>{
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Quotation = ({details}) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.ltd}>AioDock Digital</Text>
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.ribbonLabel}>Quotation to</Text>
          <Text>{details.name}</Text>
          <Text>{details.address}</Text>

        </View>
      </View>

      <View style={styles.ribbon}>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Date</Text>
          <Text style={styles.ribbonValue}>{details.date}</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Quotation No #</Text>
          <Text style={styles.ribbonValue}>{details.id}</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Amount</Text>
          <Text style={styles.ribbonValue}>{numberWithCommas((parseInt(details.width * details.height * (details.rate - parseInt(details.discount))) * details.screen))} Rs</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRowB}>
          <Text style={styles.tableHeadingA}>
            Description
          </Text>
          <Text style={styles.tableHeadingB}>
            No of Screen
          </Text>
          <Text style={styles.tableHeadingB}>
            Total Length
            {'\n'}
            (Sq. Feets)
          </Text>
          <Text style={styles.tableHeadingC}>
            Rate / Sq. Feets
          </Text>
          <Text style={styles.tableHeadingLast}>
            Total
          </Text>
        </View>

        <View style={styles.tableRowA}>
          <Text style={styles.serviceDescription}>
            <Text style={styles.module}>{details.module} SMD {details.category}</Text>
            {'\n'}
            {"Full Color with Complete Installation" }
            {'\n'}
            <Text style={styles.serviceAmount}>Actual Size</Text> <Text style={styles.module}>{details.width} Ft (W)</Text> by <Text style={styles.module}>{details.height} Ft (H)</Text>
            {'\n'}
          </Text>
          <Text style={styles.serviceAmount}>
          {details.screen}
          </Text>
          <Text style={styles.serviceAmount}>
           {(details.width * details.height).toFixed(2)}
          </Text>
          <Text style={styles.serviceAmount}>
           {numberWithCommas(details.rate)}
           <Text style={{color:"#0084B4",  fontSize: 8}}> {'\n Quoted: \n'}</Text>
           <Text style={{color:"red"}}>{numberWithCommas(details.rate - parseInt(details.discount))}</Text>
          </Text>
          <Text style={styles.serviceAmountLast}>
           {numberWithCommas(parseInt(details.width * details.height * details.rate) * details.screen)}
           <Text style={{color:"#0084B4", fontSize: 8}}> {'\n Quoted: \n'}</Text>
           <Text style={{color:"red"}}>{numberWithCommas((parseInt(details.width * details.height * (details.rate - parseInt(details.discount))) * details.screen))}</Text>
          </Text>
        </View>

        <View style={styles.summary}>
          <Text style={styles.summaryMeta}>
            Valid Till Date: {details.dueDate}
          </Text>
          <Text style={styles.summaryAmount}>
          <Text style={{color:"red"}}>Special Discount : {numberWithCommas(parseInt(details.discount * details.width * details.height))} Rs</Text>
          {'\n'}Final Price : {numberWithCommas((parseInt(details.width * details.height * (details.rate - parseInt(details.discount))) * details.screen))} Rs
          </Text>
        </View>
      </View>

      <Text style={styles.howToPay}>Specifications</Text>

      <View style={styles.ribbonGrey}>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Modules</Text>
          <View style={styles.couple}>
            <Image style={styles.No1Brand} src={No1Brand} />
            <Image style={styles.fiba} src={fiba} />
          </View>
          <Text style={styles.ribbonValue}>Unilumin</Text>
          <Image style={styles.auth} src={auth} />
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Card</Text>
          <View style={styles.couple}>
            <Image style={styles.huidu} src={huidu} />
          </View>
          <Text style={styles.ribbonValue}>Huidu HD Tech</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Supply</Text>
          <Image style={styles.genergy} src={genergy} />
          <Text style={styles.ribbonValue}>G-Energy</Text>
        </View>
      </View>
      <Text style={styles.TermsCondition}>Terms & Conditions</Text>
      <Text style={styles.TermsConditionLine}>Payment 100% Advance/Issuance of Purchase Order.</Text> 
      <Text style={styles.TermsConditionLine}>Service Warranty: 01 Years after date of delivery.</Text>
      <Text style={styles.TermsConditionLine}>Delivery Time : 10 Days after Payment, subject to availablitiy of Stock.</Text>
      <Text style={styles.TermsConditionLine}>Stabilized Power is responsibilty of Client.</Text>
      <View style={styles.footer}>
        <Text>
        Building No # 112, Safari Villa-1,  Bahria Town, Rawalpindi
        {'\n'}
          info@aiodock.com &bull;
          +92 319 7745430
        </Text>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  couple: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center"
  },
  huidu: {
    width: 100,
    height: 25,
    marginLeft: 40,
    marginTop: 12.5,
    marginBottom: 12.5
  },
  No1Brand: {
    width: 80,
    height: 50,

  },
  genergy: {
    width: 50,
    height: 40,
    marginLeft: 65,
    marginTop: 5,
    marginBottom: 5
  },
  fiba: {
    width: 80,
    height: 50,

  },
  logo: {
    width: 100,
    height:60,

  },
  auth: {
    width: 120,
    height: 50,
    position:"absolute",
    marginTop:100,
    marginLeft:30
  },
  header: {
    borderTopWidth: 4,
    borderTopColor: '#0084B4',
    color: '#898989',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  headerLeft: {
    textAlign: 'left',
    flex: 1,
    alignSelf: 'center',
    fontSize: 32,
    fontWeight: 900,
    lineHeight: 1,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  headerRight: {
    textAlign: 'right',
    fontSize: 12,
    flex: 1,
    fontFamily: 'MontserratRegular',
  },

  ribbon: {
    backgroundColor: '#0084B4',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#FFF',
  },
  ribbonGrey: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#0084B4',
  },
  ribbonBox: {
    width: '33.333333%',
  },
  ribbonLabel: {
    fontSize: 14,
    fontFamily: 'KadwaBold',
  },
  ribbonValue: {
    fontSize: 18,
    fontFamily: 'KadwaBold',
  },

  table: {
    paddingHorizontal: 20,
    display: 'flex',
    marginBottom: 20,
  },
  tableRowA: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  tableRowB: {
    padding: 10,
    textAlign: "center",
    display: 'flex',
    flexDirection: 'row',
  },
  serviceName: {
    fontSize: 10,
    width: '25%',
    fontFamily: 'KadwaBold',
  },
  serviceDescription: {
    fontSize: 8,
    width: '40%',
    fontFamily: 'MontserratRegular',
  },
  module: {
    fontSize: 12,
    width: '40%',
    color: "red",
    fontFamily: 'KadwaBold',
  },
  serviceAmount: {
    fontSize: 10,
    width: '15%',
    textAlign: 'center',
    fontFamily: 'KadwaBold',
  },
  serviceAmountLast: {
    fontSize: 10,
    width: '15%',
    textAlign: 'right',
    fontFamily: 'KadwaBold',
  },
  tableHeadingA: {
    width: '40%',
    fontSize: 10,
    textAlign: "left",
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  tableHeadingB: {
    width: '15%',
    fontSize: 10,
    textAlign: "center",
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  tableHeadingC: {
    width: '15%',
    textAlign: "center",
    fontSize: 10,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  tableHeadingLast: {
    width: '15%',
    textAlign: "right",
    fontSize: 10,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  summary: {
    backgroundColor: '#EDEDED',
    color: '#FFF',
    padding: 5,
    paddingLeft:10,
    paddingRight:10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryMeta: {
    width: '40%',
    fontSize: 15,
    color:"#0084B4",
    fontFamily: 'KadwaBold',
  },
  summaryAmount: {
    fontSize: 15,
    width: '60%',
    color:"black",
    textAlign: 'right',
    fontFamily: 'KadwaBold',
  },

  howToPay: {
    paddingHorizontal: 20,
    textAlign: 'center',
    fontSize: 20,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  ltd: {
    textAlign: 'left',
    fontSize: 16,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  TermsCondition: {
    paddingHorizontal: 20,
    textAlign: 'center',
    fontSize: 15,
    color: 'black',
    fontFamily: 'KadwaBold',
  },
  TermsConditionLine: {
    textAlign: 'center',
    fontSize: 8,
    color: 'black',
    fontFamily: 'MontserratRegular',
  },

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
    borderTopColor: '#EDEDED',
    borderTopWidth: 1,
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'MontserratRegular',
  },
});

export default Quotation;